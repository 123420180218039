footer {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #0B090A;
  color: white;
  font-family: 'Monckeberg';
  font-size: 1em;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#websiteDev {
  text-align: end;
  padding-right: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


@media only screen and (max-width: 1200px) {
  footer {
      font-size: 0.9em;
  }
}

@media only screen and (max-width: 800px) {
  footer {
      font-size: 0.8em;
      flex-direction: column;
      text-align: center;
  }

  #websiteDev {
      text-align: center;
      padding-right: 0;
  }
}

@media only screen and (max-width: 600px) {
  footer {
      font-size: 0.7em;
  }
}
