.TestimonalsContainer {
    text-align: center;
    padding-top: 130px; 
}

.counterImg {
    width: 50%;
}

#testHeader {
    position: relative;
    color: #DBDADA;
    font-size: 4em;
    text-align: center;
    font-family: 'Monckeberg Light';
}

#testHeader::after {
    content: "";
    display: block;
    width: 200px; 
    height: 3px; 
    background-color: #E5383B;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.Testimonials {
    max-width: 400px; 
    margin: auto; 
}

.swiper-container {
    overflow: hidden;
    width: auto; 
    height: 175px; 
}

.swiper-slide {
    text-align: center;
    font-size: 24pt;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
    color: #DBDADA;
    font-family: 'Monckeberg Light';
    border: 2px solid transparent;
}

.testimonial-author {
    font-style: italic;
    color: #E5383B;
    margin-top: 5px; 
    font-family: 'Monckeberg Light';
}


/* Mobile */
@media only screen and (max-width: 1200px) {
    #testHeader {
        font-size: 3em;
    }

    .swiper-slide {
        font-size: 20pt;
    }

    .TestimonalsContainer {
        padding-top: 100px;
        padding-bottom: 120px;
    }
}

@media only screen and (max-width: 800px) {
    #testHeader {
        font-size: 2.5em;
    }

    .swiper-slide {
        font-size: 18pt;
    }

    .testimonial-author {
        font-size: 16pt;
        margin-top: 5px;
    }

    .TestimonalsContainer {
        padding-top: 80px;
        padding-bottom: 100px;
    }

    .Testimonials {
        max-width: 90%;
    }

    .swiper-container {
        height: 150px;
    }
}

@media only screen and (max-width: 600px) {
    #testHeader {
        font-size: 2em;
    }

    .swiper-slide {
        font-size: 16pt;
    }

    .testimonial-author {
        font-size: 14pt;
        margin-top: 5px;
    }

    .TestimonalsContainer {
        padding-top: 60px;
        padding-bottom: 80px;
    }

    .Testimonials {
        max-width: 100%;
    }

    .swiper-container {
        height: 125px;
    }
}
