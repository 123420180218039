/* header.css */
.header {
    height: 100px;
    width: 100%;
    background-color: #0B090A;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.7);
    box-sizing: border-box;
    position: relative;
    z-index: 1000;
}

.navBar {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.navLinks {
    display: flex;
    gap: 50px;
    margin-left: auto;
}

.navElement {
    color: white;
    font-size: 18pt;
    font-family: 'Monckeberg';
    text-decoration: none;
    position: relative;
    user-select: none;
}

.red-bar {
    position: absolute;
    bottom: -3px;
    height: 4px;
    background-color: red;
    width: 0;
    transition: width 0.3s ease;
    left: 0;
}

.navElement:hover .red-bar,
.navElement.active .red-bar {
    width: 100%;
}

#mainNavImg {
    height: 70px;
    margin-right: auto;
}

.language-button {
    background-color: #E5383B;
    color: white;
    font-size: 16pt;
    font-family: 'Monckeberg';
    border: none;
    padding: 5px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.language-button:hover {
    background-color: rgb(212, 14, 14);
}

.dropdown-button {
    display: none;
    background: none;
    border: none;
    color: white;
    font-size: 35px;
    cursor: pointer;
}

.close-button {
    display: none;
}

/* Mobile Responsive Styles */
@media only screen and (max-width: 1000px) {
    .header {
        padding: 0 20px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .navBar {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        position: relative;
    }

    .navLinks {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 0;
        overflow: hidden;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.9);
        opacity: 0;
        transform: translateY(-100%);
        padding-bottom: 150px;
        z-index: 999;
        transition: height 0.5s ease, opacity 0.5s ease, transform 0.5s ease;
    }

    .navLinks.mobile-menu-open {
        height: 100%;
        opacity: 1;
        transform: translateY(0);
    }

    #mainNavImg {
        order: 1;
        height: 60px;
        margin-right: auto;
    }

    .dropdown-button {
        display: block;
        order: 2;
    }

    .language-button {
        display: none;
    }

    .navLinks .language-button {
        display: block;
        width: 20%;
        text-align: center;
        padding: 10px 0;
    }

    .close-button {
        display: none;
    }

    .close-button.visible {
        display: block;
        position: absolute;
        top: 10px;
        right: 20px;
        background: none;
        border: none;
        color: white;
        font-size: 35px;
        cursor: pointer;
    }
}
