.servicesText {
    display: flex;
    flex-direction: column;
    text-align: center;
}

#serviceHeader {
    color: #DBDADA;
    text-align: center;
    align-content: center;
    font-size: 4em;
    font-family: 'Monckeberg Light';
    padding-top: 20px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#serviceHeader::after {
    content: "";
    display: block;
    width: 200px; 
    height: 3px; 
    background-color: #E5383B;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

#servicesItalics {
    color: white;
    font-size: 3em;
    font-family: 'Monckeberg Light';
    font-style: italic;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#servicesParagraph {
    color: white;
    font-size: 1.8em;
    font-family: 'Monckeberg Light';
    max-width: 70%;
    margin: 0 auto;
    padding-bottom: 50px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.servicesContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly; 
    align-items: center;
    flex-wrap: wrap;
}

.serviceImg {
    max-width: 100%;
    height: 550px;
    opacity: 1;
    transition: .5s ease;
    border-style: solid;
    border-color: white;
    border-radius: 10px;
    border-width: 10;
    margin-top: 20px;
    position: relative;
}

#shaking {
    height: 550px;
    max-width: 50%;
    border-style: solid;
    border-color: white;
    border-radius: 10px;
    border-width: 10;
    margin-top: 20px;
}

.servicesDivs {
    position: relative;
    overflow: hidden;
}

.servicesDivs .overlay-box {    
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    margin-top: 70%;
    opacity: 0;
    transition: opacity 0.5s ease;
    z-index: 1;
    overflow: hidden;
}

.servicesDivs:hover .serviceImg {
    opacity: 0.5;
}

.servicesDivs:hover .overlay-box {
    opacity: 1;
}

.serviceDescriptionMobile {
    display: none;
}

.serviceDescription {
    color: white;
    font-size: 32pt;
    font-family: 'Monckeberg';
    padding-left: 10px;
    padding-bottom: 150px;
    text-align: center;
}

.hidden {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.animate {
    opacity: 1;
    transform: translateY(0);
}

@media only screen and (max-width: 1200px) {
    #serviceHeader {
        font-size: 3em;
    }

    #servicesItalics {
        font-size: 2.5em;
    }

    #shaking {
        display: none;
    }

    #servicesParagraph {
        font-size: 1.5em;
    }

    .serviceDescription {
        font-size: 28pt;
    }
}

@media only screen and (max-width: 800px) {
    #serviceHeader {
        font-size: 2.5em;
    }

    #servicesItalics {
        font-size: 2em;
    }
    
    #servicesParagraph {
        font-size: 1.2em;
    }

    .serviceDescription {
        font-size: 24pt;
    }

    .serviceImg {
        max-height: 300px;
    }

    .servicesDivs:hover .serviceImg {
        opacity: 1;
    }

    .servicesDivs:hover .overlay-box {
        opacity: 0;
    }

    .serviceDescription {
        display: none;
    }

    .serviceDescriptionMobile {
        display: block; 
        color: white;
        font-size: 2.6vh; 
        font-family: 'Monckeberg';
        overflow: hidden;
        z-index: 2;
        text-align: center;
    }
}

@media only screen and (max-width: 600px) {
    #serviceHeader {
        font-size: 2em;
    }

    #servicesItalics {
        font-size: 1.8em;
    }

    #servicesParagraph {
        font-size: 1em;
    }

    .serviceDescription {
        font-size: 20pt;
    }
}
