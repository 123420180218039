#contactHeader {
    color: #DBDADA;
    text-align: center;
    font-size: 4em;
    font-family: 'Monckeberg Light';
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#contactHeader::after {
    content: "";
    display: block;
    width: 200px;
    height: 3px;
    background-color: #E5383B;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.contactsSection {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    font-size: 18pt;
    margin-bottom: 100px;
    font-family: 'Monckeberg Light';
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.emailDiv,
.callDiv,
.socialDiv {
    background-color: #E5383B;
    padding: 20px;
    border-style: solid;
    border-color: black;
    border-width: 5px;
    margin: 0 20px;
    transition: background-color 0.3s ease; /* Added transition for background color */
}

.emailDiv:hover,
.callDiv:hover,
.socialDiv:hover {
    background-color: rgb(212, 14, 14); /* Change background color on hover */
    cursor: pointer;
}

#instaPhoto {
    width: 165px;
    height: auto;
    padding-left: 15px;
}

.phoneNumber,
.email {
    color: white;
}

@media only screen and (max-width: 800px) {

    #contactHeader {
        font-size: 2.5em;
    }

    .contactsSection {
        flex-direction: row;
        align-items: center;
    }

    .emailDiv,
    .callDiv,
    .socialDiv {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        margin: 20px 0;
        padding: 0;
        transition: background-color 0.3s ease;
    }

    .callDiv img,
    .emailDiv img,
    .socialDiv img {
        width: 60%;
        height: auto;
        margin-top: 25%;
    }

    #instaPhoto {
        max-width: 50%;
        padding-left: 5px;
    }

    .callUs,
    .emailUs,
    .phoneNumber,
    .email {
        display: none;
    }

    .callDiv:hover,
    .socialDiv:hover,
    .emailDiv:hover {
        width: 100px;
        height: 100px;
        background-color: rgb(212, 14, 14); /* Change background color on hover */
    }
}
