

.homeSection {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
}

#homeSlogan {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    height: auto;
    padding-top: 5px;
    justify-content: center;
    color: white;
    font-family: 'Monckeberg';
    font-size: 6vw;
    font-weight: 1000;
    white-space: nowrap;
    opacity: 0;
    animation: fadeInSlogan 4s ease forwards;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@keyframes fadeInSlogan {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

#homeimg1, #homeimg2 {
    height: auto;
    max-width: 50%;
}




@media only screen and (max-width: 800px) {

    #homeSlogan {
        font-size: 8vw;
        top: 15px;
    }

    #homeimg1 {
        max-width: 100%;
        margin-bottom: 10px;
    }

    #homeimg2 {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    #homeSlogan {
        font-size: 8vw;
        top: 10px;
    }

    #homeimg1 {
        max-width: 100%;
        margin-bottom: 10px;
    }
}
