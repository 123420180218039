#glassesContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.glasses, .martinis {
    margin: 10px 20px;
}

.glasses {
    max-width: 120px;
}

.martinis {
    max-width: 170px;
}

/* Mobile */
@media only screen and (max-width: 800px) {
    .glasses {
        max-width: 100px;
        margin: auto 
    }
    
    .martinis {
        max-width: 150px; 
        margin: auto;
    }
}

@media only screen and (max-width: 600px) {
    .glasses {
        max-width: 80px; 
    }
    
    .martinis {
        max-width: 130px; 
    }
}

@media only screen and (max-width: 400px) {
    .glasses {
        max-width: 60px; 
    }
    
    .martinis {
        max-width: 110px; 
    }
}
