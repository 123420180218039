@font-face {
    font-family: 'Monckeberg';
    src: local('Monckeberg Regular'), local('Monckeberg-Regular'),
        url('Fonts/Monckeberg-Regular.woff2') format('woff2'),
        url('Fonts/Monckeberg-Regular.woff') format('woff'),
        url('Fonts/Monckeberg-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Monckeberg Light';
    src: local('Monckeberg Light'), local('Monckeberg-Light'),
        url('Fonts/Monckeberg-Light.woff2') format('woff2'),
        url('Fonts/Monckeberg-Light.woff') format('woff'),
        url('Fonts/Monckeberg-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

body{
    margin: 0;
    padding: 0;
    background-color: #000000;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23313131' fill-opacity='0.84' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
}

html {
  scroll-behavior: smooth;
}

.p{
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

}
