@keyframes float {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

.aboutUsHeader {
    color: #DBDADA;
    font-size: 4em;
    text-align: center;
    font-family: 'Monckeberg Light';
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.aboutUsHeader::after {
    content: "";
    display: block;
    width: 200px; 
    height: 3px; 
    background-color: #E5383B;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.aboutSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    text-align: left;
    padding-bottom: 150px;
}

.foundersPic {
    max-width: 30%;
    height: auto;
    border: solid #DBDADA;
    border-radius: 10px;
}

#aboutDescription {
    font-size: 1.8em;
    color: white;
    max-width: 800px;
    font-family: 'Monckeberg Light';
    text-align: center;
    padding: 15px;
    border: 2px solid white;
    border-radius: 10px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
    animation: float 3s ease-in-out infinite;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}



@media only screen and (max-width: 1200px) {

    .foundersPic {
        max-width: 60%;
        padding-right: 0;
        margin-bottom: 20px;
    }


    .aboutUsHeader {
        font-size: 3em;
    }

    #aboutDescription {
        font-size: 1.5em;
    }

    .aboutSection {
        flex-direction: column;
        text-align: center;
        padding-bottom: 50px;
    }
}

@media only screen and (max-width: 800px) {
    .aboutUsHeader {
        font-size: 2.5em;
    }

    .foundersPic {
        max-width: 60%;
        padding-right: 0;
        margin-bottom: 20px;
    }

    #aboutDescription {
        max-width: 80%;
        font-size: 1.2em;
    }
}

@media only screen and (max-width: 600px) {
    .aboutUsHeader {
        font-size: 2em;
    }

    #aboutDescription {
        font-size: 1em;
        max-width: 400px;
    }

    .foundersPic {
        max-width: 80%;
    }
}
